@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/colors/_colors.scss';

$lineHeight: 16px;

$columnWidthInput: 14;
$gutterWidthInput: 16;

$columnWidthXSmall: 14;
$gutterWidthXSmall: 16;

$columnWidthSmall: 65;
$gutterWidthSmall: 32;

@mixin solidBackground {
  background-color: colors.$surfaceColourTintDark;
  z-index: -1;
  content: "";
  position: absolute!important;
  top: 0;
  bottom: 0;
  left: -9990px;
  right: 0;
  border-left: 9999px solid colors.$surfaceColourTintDark;
  box-shadow: 9999px 0 0 colors.$surfaceColourTintDark;
}

@function responsiveGrid($containerColumns, $columns, $halfGutters, $size) {
  @if $size == "xSmall" {
    $totalColumnGutterWidth: $gutterWidthXSmall + $columnWidthXSmall;
    $totalWidth: $totalColumnGutterWidth * $containerColumns;
    $halfGutterWidth: $gutterWidthXSmall * .5;
    $width: percentage(calc($columns / $containerColumns));
    $gutter: percentage(calc($halfGutterWidth / $totalWidth));
    $outPut: $width + ($gutter * $halfGutters);
    @return calc(round($outPut * 100) / 100);
  }
  @if $size == "small" {
    $totalColumnGutterWidth: $gutterWidthSmall + $columnWidthSmall;
    $totalWidth: $totalColumnGutterWidth * $containerColumns;
    $halfGutterWidth: $gutterWidthSmall * .5;
    $width: percentage(calc($columns / $containerColumns));
    $gutter: percentage(calc($halfGutterWidth / $totalWidth));
    $outPut: $width + ($gutter * $halfGutters);
    @return calc(round($outPut * 100) / 100);
  }
}

@mixin responsiveGrid($columnContainer, $columns, $margins, $property) {
  #{$property}: responsiveGrid($columnContainer, $columns, $margins, "xSmall");
  @include breakpoints.respond-to("tablet") {
      #{$property}: responsiveGrid($columnContainer, $columns, $margins, "small");
    }
}

@function responsiveMargin($columnWidthInput, $gutterWidthInput) {
  $totalColumnGutterWidth: $columnWidthInput + $gutterWidthInput;  
  $totalWidth: $totalColumnGutterWidth * 12 + ($gutterWidthInput);
  $totalPercent: percentage(calc($gutterWidthInput / $totalWidth));
  @return $totalPercent*.5;
}

@mixin responsiveMargin($property) {
  #{$property}: responsiveMargin($columnWidthXSmall, $gutterWidthXSmall);
  @include breakpoints.respond-to("tablet") {
    #{$property}: responsiveMargin($columnWidthSmall, $gutterWidthSmall);
  }
  @include breakpoints.respond-to("desktop") {
    #{$property}: 16px;
  }
}

@mixin responsiveCssGrid($columnContainer, $columns) {
  grid-template-columns: responsiveGrid($columnContainer, $columns, 0, "xSmall") 1fr;
  @include breakpoints.respond-to("tablet") {
    grid-template-columns: responsiveGrid($columnContainer, $columns, 0, "small") 1fr;
  } 
}

@mixin responsiveCssGridGap($columnContainer, $columns, $margins) {
  gap: responsiveGrid($columnContainer, $columns, $margins, "xSmall");
  @include breakpoints.respond-to("tablet") {
    gap: responsiveGrid($columnContainer, $columns, $margins, "small");
  } 
}