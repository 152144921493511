@use '/src/scss/layout/_breakpoints.scss';
$columnWidthXSmall: 14;
$gutterWidthXSmall: 16;

$columnWidthSmall: 88;
$gutterWidthSmall: 32;

@function lbGrid($containerColumns, $columns, $halfGutters, $size) {
  @if $size == "xSmall" {
    $totalColumnGutterWidth: $gutterWidthXSmall + $columnWidthXSmall;
    $totalWidth: $totalColumnGutterWidth * $containerColumns;
    $halfGutterWidth: $gutterWidthXSmall * .5;
    $width: percentage(calc($columns / $containerColumns));
    $gutter: percentage(calc($halfGutterWidth / $totalWidth));
    $outPut: $width + ($gutter * $halfGutters);
    @return calc(round($outPut * 100) / 100);
  }
  @if $size == "small" {
    $totalColumnGutterWidth: $gutterWidthSmall + $columnWidthSmall;
    $totalWidth: $totalColumnGutterWidth * $containerColumns;
    $halfGutterWidth: $gutterWidthSmall * .5;
    $width: percentage(calc($columns / $containerColumns));
    $gutter: percentage(calc($halfGutterWidth / $totalWidth));
    $outPut: $width + ($gutter * $halfGutters);
    @return calc(round($outPut * 100) / 100);
  }
}

@mixin lbGrid($columnContainer, $columns, $margins, $property) {
  #{$property}: lbGrid($columnContainer, $columns, $margins, "xSmall");
  @include breakpoints.respond-to("tablet") {
      #{$property}: lbGrid($columnContainer, $columns, $margins, "small");
    }
}

@function lbGridInnerPage($columnWidthInput, $gutterWidthInput) {
  $totalColumnGutterWidth: $columnWidthInput + $gutterWidthInput;  
  $totalWidth: $totalColumnGutterWidth * 12 + ($gutterWidthInput);
  $totalPercent: percentage(calc($gutterWidthInput / $totalWidth));
  @return $totalPercent*.5;
}

@mixin lbGridInnerPage($property) {
  #{$property}: lbGridInnerPage($columnWidthXSmall, $gutterWidthXSmall);
  @include breakpoints.respond-to("tablet") {
    #{$property}: lbGridInnerPage($columnWidthSmall, $gutterWidthSmall);
  }
  @include breakpoints.respond-to("desktopWide") {
    #{$property}: 16px;
  }
}

@mixin layoutSection-outerPage {
  width: 100%;
  display: block;
}

@mixin layoutSection-innerPage {
  @include lbGridInnerPage("padding-left");
  @include lbGridInnerPage("padding-right");
  max-width: 1472px!important;
  margin: 0 auto;
  text-align: left;
}

.lb_layoutSection-outerPage {
  @include layoutSection-outerPage;
}

.lb_layoutSection-innerPage {  
  @include layoutSection-innerPage;
}