@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/layout/abstracts/_lbGrid.scss';

.lb_categoryCarousel {

    img:hover {
        cursor: pointer;
    }
    
}

.lb_categoryCarousel,
.lb_brandCarousel {
    .lb_carousel_cell {
    width: 39%;
        @include breakpoints.respond-to("tablet") {
            width: 28%;
        }
    }

    @include breakpoints.respond-to("desktop") {
        margin-left: auto;
        margin-right: auto;
    }
}

@include breakpoints.respond-to("desktop") {

    [data-carousel-cells-per-page="6"] .lb_carousel_cell {
    @include layout.responsiveGrid(6, 1, 0, "width");
    }

    [data-carousel-cells-per-page="7"] .lb_carousel_cell {
    @include layout.responsiveGrid(7, 1, 0, "width");
    }

    [data-carousel-cells-per-page="8"] .lb_carousel_cell {
    @include layout.responsiveGrid(8, 1, 0, "width");
    }

}

.lb_brandCarousel .lb_carousel_cellsWrapper {
    align-items: center;
}

[data-home="true"]{
   grid-template-columns: 100%;
    display: grid;
    grid-template-areas:
    "data-home-category-carousel"
    "data-home-banner-1"
    "data-home-brand-carousel"
    "data-home-internal-links"
    "data-home-banner-2"
    "data-home-recently-viewed"
    "data-home-buying-guides"
    "data-home-intro"
    "data-home-banner-3";
}

[data-home-category-carousel="true"]{
    grid-area: data-home-category-carousel;
}

[data-home-banner-1="true"]{
    grid-area: data-home-banner-1;
}

[data-home-brand-carousel="true"]{
    grid-area: data-home-brand-carousel;
}

[data-home-internal-links="true"]{
    grid-area: data-home-internal-links;
}

[data-home-banner-2="true"]{
    grid-area: data-home-banner-2;
}

[data-home-recently-viewed="true"]{
    grid-area: data-home-recently-viewed;
}

[data-home-buying-guides="true"]{
    grid-area: data-home-buying-guides;
}

[data-home-intro="true"]{
    grid-area: data-home-intro;
}

[data-home-banner-3="true"]{
    grid-area: data-home-banner-3;
}

.lb_tilesHome {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    @include lbGrid.lbGrid(12, 0, 1.99, "gap");
    @include lbGrid.lbGrid(12, 0, 1, "margin-left");
    @include lbGrid.lbGrid(12, 0, 1, "margin-right");
  }
  
.lb_tilesHome_cell {
    width: 100%;
    @include lbGrid.lbGrid(12, 0, 2, "margin-bottom");
    justify-content: stretch;
    display: flex;
  }
  
.lb_tilesHome-tabletx2 .lb_tilesHome_cell {
    @include breakpoints.respond-to("tablet") {
        @include lbGrid.lbGrid(12, 6, -1, "width");
    }
}