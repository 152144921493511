@mixin respond-to($media) {
  @if $media == tablet {
    @media only screen and (min-width: 768px) { @content; }
  }
  @else if $media == desktop {
    @media only screen and (min-width: 1200px) { @content; }
  }
  @else if $media == desktopWide {
    @media only screen and (min-width: 1472px) { @content; }
  }
}

@mixin orientation($media) {
  @if $media == portrait {
    @media screen and (orientation:portrait) { @content; }
  }
  @else if $media == landscape {
    @media screen and (orientation:landscape) { @content; }
  }
}